<template>
  <div>
    <div class="bg-white rounded-md">
      <h4 class="flex flex-wrap items-center justify-between leading-none m-0 p-2">
        <span class="font-semibold text-big-stone">{{ title }}</span>
        <icon-status-error v-if="hasError" class="w-6 h-6" />
        <icon-status-ok v-else class="w-6 h-6" />
      </h4>
      <table class="mdbpro-table">
        <thead class="bg-big-stone bg-opacity-90 text-white">
          <tr>
            <th class="text-center font-semibold text-sm">Type</th>
            <th class="text-center font-semibold text-sm" v-if="hasItem('count')">Count</th>
            <th class="text-center font-semibold text-sm" v-if="hasItem('full')">Full</th>
            <th class="text-center font-semibold text-sm" v-if="hasItem('jam')">Jam</th>
          </tr>
        </thead>
        <tbody v-if="data">
          <tr v-for="(item, index) in data.items" :key="index">
            <td class="text-center font-bold">{{ item.type }}</td>
            <td class="text-center font-bold" v-if="hasItem('count')">{{ item.count }}</td>
            <td v-if="hasItem('full')"><span class="led m-auto" :data-plcstatus="item.full ? 1 : 0"></span></td>
            <td v-if="hasItem('jam')"><span class="led m-auto" :data-plcstatus="item.jam ? 1 : 0"></span></td>
          </tr>
          <tr v-if="data.total_cents" class="bg-big-stone text-white">
            <td class="text-center font-bold">Total</td>
            <td class="font-bold text-center">{{ formatMoney(data.total_cents / 100) }}</td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="data && hasError" class="mt-1 text-red-600 italic text-sm text-center w-full">
      <div v-for="(error, index) in data.errors" :key="index">{{ error.detail }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { convertErrorsToArray } from '@/helpers/machine.js'
import { IconStatusOk, IconStatusError } from '@/components/icons'
import money from '@/helpers/money.js'

export default {
  name: 'MachinesDetailBillValidator',

  components: {
    IconStatusOk,
    IconStatusError,
  },

  props: ['data', 'title'],

  setup(props) {
    const errors = computed(() => {
      if (!props.data?.errors) return []

      return convertErrorsToArray(props.data.errors)
    })

    const hasError = computed(() => {
      if (!props.data) return true

      return errors.value && errors.value.length > 0
    })

    const hasItem = (type) => {
      if (!props.data?.items && !Array.isArray(props.data?.items)) return false
      if (props.data.items.length === 0) return false

      return props.data.items[0][type] !== undefined
    }

    return {
      hasError,
      hasItem,
      formatMoney: money.format,
    }
  }
}
</script>
