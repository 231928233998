<template>
  <div>
    <div class="flex justify-between text-xs font-semibold text-gray-500">
      <div class="p-1">{{ min }} {{ unit }}</div>
      <div class="p-1">{{ max }} {{ unit }}</div>
    </div>
    <!-- Container for progress bar and threshold marker -->
    <div class="relative">
      <!-- Threshold label (positioned above the bar) -->
      <div
        class="absolute -top-5 text-xs text-gray-900 font-semibold transform -translate-x-1/2 bg-gray-100 px-1"
        :style="{ left: thresholdPercentage + '%' }"
      >
        <a :href="`/settings/machines/`+machineId+`/edit/#notifications`" target="_blank" class="group inline-flex items-center gap-x-0.5">
          {{ threshold }} {{ unit }}
          <Cog class="w-3 h-3 text-gray-500 transition duration-200 ease-in-out group-hover:text-gray-600" />
        </a>
      </div>
      <!-- Background bar container -->
      <div class="relative bg-gray-200 h-4 rounded-full w-full">
        <!-- Vertical dashed threshold marker -->
        <div
          class="absolute h-full border-l-2 border-dashed border-gray-700"
          :style="{ left: thresholdPercentage + '%' }"
        ></div>
        <!-- Foreground bar showing progress -->
        <div
          :class="['h-full rounded-full transition-all', gradientClass]"
          :style="{ width: percentage + '%' }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import Cog from "@/components/icons/Cog.vue";

export default {
  name: 'MeterWidget',
  components: {Cog},
  props: {
    label: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    // For air pressure, pass threshold as 0 (red if value is 0); for temperature, pass threshold as 100 (red if >=100)
    threshold: {
      type: Number,
      required: true
    },
    // New prop: set to true if below threshold should be green (default), false if below threshold should be red.
    greenBelowThreshold: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // Calculate percentage of progress (ensuring it's between 0 and 100)
    percentage() {
      let pct = ((this.value - this.min) / (this.max - this.min)) * 100;
      return Math.min(Math.max(pct, 0), 100);
    },
    // Calculate threshold position as a percentage of the bar's width
    thresholdPercentage() {
      let pct = ((this.threshold - this.min) / (this.max - this.min)) * 100;
      return Math.min(Math.max(pct, 0), 100);
    },
    // Determine gradient class based on the threshold and the greenBelowThreshold flag
    gradientClass() {
      // When threshold is 0, use a special case logic
      if (this.threshold === 0) {
        if (this.greenBelowThreshold) {
          return this.value === 0
            ? 'bg-gradient-to-r from-red-400 to-red-600'
            : 'bg-gradient-to-r from-green-400 to-green-600';
        } else {
          return this.value === 0
            ? 'bg-gradient-to-r from-green-400 to-green-600'
            : 'bg-gradient-to-r from-red-400 to-red-600';
        }
      } else {
        if (this.greenBelowThreshold) {
          return this.value < this.threshold
            ? 'bg-gradient-to-r from-green-400 to-green-600'
            : 'bg-gradient-to-r from-red-400 to-red-600';
        } else {
          return this.value < this.threshold
            ? 'bg-gradient-to-r from-red-400 to-red-600'
            : 'bg-gradient-to-r from-green-400 to-green-600';
        }
      }
    }
  }
}
</script>
