<template>
  <div class="space-y-4">
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-x-2">
        <Terminal class="w-5 h-5 text-big-stone" />
        <h3>Machine Log</h3>
      </div>
    </div>

    <div ref="logWrapper" class="log-detail-wrapper relative bg-concrete mb-2 hide-scrollbar">
      <div v-if="isLoading" class="sticky inset-0 h-full bg-gray-50 opacity-75">
        <div class="flex justify-center items-center w-full h-full">
          <loading class="text-blumine w-5 h-5" />
        </div>
      </div>
      <template v-for="(log, index) in logs" :key="index">
        <div v-if="log.header">
          <div class="bg-gray-200 text-gray-900 font-semibold px-1 py-1 text-xs font-mono tracking-tight rounded-sm">
            {{ log.header }}
          </div>
        </div>
        <div class="py-1 px-1 font-mono text-xs tracking-tight" :class="{ 'border-b': index !== logs.length - 1 }">
          <span class="text-gray-800">{{ log.timestamp }}</span>
          <span class="ml-2">
            <span class="uppercase font-semibold" :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true }">{{ log.event_type }}</span>
            <span class="ml-1 font-semibold" :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true  }">{{ log.event }}</span> <span class="text-gray-800" v-if="log.username">by</span> <span class="font-semibold text-shakespear">{{ log.username }}</span>
          </span>
        </div>
      </template>
    </div>

    <div class="flex justify-between items-center gap-x-4">
      <label class="text-xs gap-x-1 flex items-center">
        <input type="checkbox" class="text-blumine rounded"  v-model="isAutoScrollEnabled">
        Auto Scroll Enabled
      </label>

      <span v-if="machineId" class="text-xs">
        <router-link
          :to="{ name: 'Logs', query: { locationid: machineId } }"
          class="group flex items-center gap-x-1"
        >
          <span class="text-xs text-big-stone underline">Go to Machine Logs</span>
          <svg class="w-4 h-4 text-gray-400 transition duration-200 ease-in-out group-hover:text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import date from '@/helpers/date.js'
import Loading from '@/components/icons/Loading.vue'
import Terminal from "@/components/icons/Terminal.vue";

export default {
  name: 'machines-detail-log',

  props: {
    entries: {
      type: Array,
      default: () => ([])
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    machineId: {
      type: Number,
    },
  },

  components: {
    Terminal,
    Loading
  },

  setup(props) {
    const logWrapper = ref()
    let isAutoScrollEnabled = ref(true)

    const logs = computed(() => {
      const entries = props.entries

      entries.sort((x, y) => x.timestamp - y.timestamp)

      if (isAutoScrollEnabled.value) scrollToLastLogItem()

      const usedHeaders = []
      const hasHeader = (ts) => {
        const day = date.format(ts, 'YYYY-MM-DD')
        if (usedHeaders.includes(day)) {
          return null
        }
        else {
          usedHeaders.push(day)
          return date.format(ts, 'D. MMM YYYY')
        }
      }

      return entries.map(
          (log) => ({
            ...log,
            ...{
              timestamp: date.logFormat(log.timestamp, true),
              header: hasHeader(log.timestamp),
            }
          })
        )
    })

    const scrollToLastLogItem = () => {
      setTimeout(() => logWrapper.value.scrollTop = logWrapper.value.scrollHeight)
    }

    return {
      logWrapper,
      logs,
      isAutoScrollEnabled,
    }
  }
}
</script>
