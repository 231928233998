<template>
  <div class="flex flex-wrap bg-concrete md:rounded-md md:shadow-sm">
    <div class="w-full flex items-center justify-between p-2 md:p-4">
      <h3 class="flex items-center text-lg leading-none">
        <span v-if="isLoading">
          <icon-loading class="text-blumine w-5 h-5" />
        </span>
        <span v-else>
          <icon-status-ok v-if="isOnline" />
          <icon-status-error v-else class="w-6 h-6" />
        </span>
        <span class="ml-1">{{ title }}</span>
      </h3>
      <div v-if="lastStatus" class="ml-3 flex items-center space-x-1 text-sm">
        <label class="font-medium">Last update:</label>
        <span class="font-semibold">{{ formatDate(lastStatus) }}</span>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { IconLoading, IconStatusOk, IconStatusError } from '@/components/icons'
import date from '@/helpers/date.js'

export default {
  name: 'machine-detail-card',

  components: {
    IconLoading,
    IconStatusOk,
    IconStatusError,
  },

  props: ['title', 'isOnline', 'isLoading', 'lastStatus'],

  setup() {
    const formatDate = (timestamp) => {
      return date.logFormat(timestamp, true)
    }

    return {
      formatDate
    }
  }
}
</script>
