<template>
  <template v-if="value">
    {{ money.format(parseFloat(value)) }}
  </template>
  <template v-else>
    -
  </template>
</template>

<script>
import { markRaw } from 'vue'
import money from '@/helpers/money.js'

export default {
  name: 'DatatablesFieldsMoneyField',

  props: {
    value: Number
  },

  inheritAttrs: false, // Fix: Prevent Vue from automatically inheriting unknown attributes

  setup(props, { attrs }) {
    return {
      money: markRaw(money),
      attrs, // If you need access to extra attributes
    }
  },
}
</script>
