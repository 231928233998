<template>
  <div :title="value && value.replace('-', ' ')">
    <component :is="icon" class="h-8 w-8 mx-auto" />
  </div>
</template>

<script>
import {
  IconClearDay,
  IconClearNight,
  IconCloudy,
  IconFog,
  IconPartlyCloudyDay,
  IconPartlyCloudyNight,
  IconSnow,
  IconRain,
  IconWind,
} from '@/components/icons/weather'

export default {
  name: 'DatatablesFieldsWeatherField',

  props: ['value'],

  setup(props) {
    const weatherIconLookup = {
      'clear-day': IconClearDay,
      'clear-night': IconClearNight,
      cloudy: IconCloudy,
      fog: IconFog,
      'partly-cloudy-day': IconPartlyCloudyDay,
      'partly-cloudy-night': IconPartlyCloudyNight,
      snow: IconSnow,
      rain: IconRain,
      wind: IconWind,
    }

    return {
      icon: props.value ? weatherIconLookup[props.value] : null
    }
  }
}
</script>
