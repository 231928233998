<template>
  {{ value || '-' }}
</template>

<script>
export default {
  name: 'DatatablesFieldsTextField',

  props: {
    value: [String, Number],  // Support both types
    title: String,   // Fix: Declare title explicitly
    component: String,  // Fix: Declare component explicitly
    width: String    // Fix: Declare width explicitly
  }
}
</script>
